<template>
   <v-navigation-drawer
      id="app-drawer"
      v-model="drawer"
      app
      dark
      floating
      persistent
      width="260"
   >
      <!-- <v-img
         :src="$store.state.image"
         height="100%"
      > -->
      <v-list dense>

         <v-list-item >
            <v-list-item-avatar color="white" >
               <v-img :src="logo" height="34" contain />
            </v-list-item-avatar>
            <v-list-item-title class="title">
               <span class="orange--text">PES</span> e-Survey
            </v-list-item-title>
         </v-list-item>
         <v-divider/>
         <div class="text-center my-3">
            <v-avatar color="orange" size="72" class="mt-4">
               <img alt="ava" :src="avatar">
            </v-avatar>
            <div class="subtitle-2 mt-2">{{user.nama}}</div>
            <div class="d-flex justify-center">
               <v-btn icon @click="show_profile='profile'">
                  <v-icon>mdi-account-details</v-icon>
               </v-btn>
               <v-btn icon @click="$emit('lock')">
                  <v-icon>mdi-lock</v-icon>
               </v-btn>
               <v-btn icon>
                  <v-icon @click="$emit('logout')">mdi-logout-variant</v-icon>
               </v-btn>
            </div>
         </div>
         <v-divider />
         <!-- <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            :active-class="$store.state.color"
         >
            <v-list-item-action>
               <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title
               v-text="link.text"
            />
         </v-list-item> -->
      </v-list>
      <!-- </v-img> -->
      <v-treeview
         :items="items"
         activatable
         item-key="name"
         open-on-click
      >
         <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
            <v-icon v-else>
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
         </template>
         <template slot="label" slot-scope="{ item }">
            <router-link style="display:block" v-if="item.to" :to="item.to">{{ item.name }}</router-link>
            <span v-else>{{ item.name }}</span>
         </template>
      </v-treeview>
      <profile :uid="show_profile" v-on:close="show_profile=null"/>
   </v-navigation-drawer>
</template>

<script>
import ponny from '../assets/ponny.png';
import CFG from '../config';
export default {
   components: { profile: () => import('../components/profile') },
   data: () => ({
      logo: ponny,
      links: [
         { to: '/', icon: 'mdi-view-dashboard', text: 'Dashboard' },
         { to: '/users', icon: 'mdi-account-multiple', text: 'Users' },
         { to: '/order', icon: 'mdi-clipboard-outline', text: 'Oreder' },
         { to: '/typography', icon: 'mdi-format-font', text: 'Typography' },
         { to: '/icons', icon: 'mdi-chart-bubble', text: 'Icons' },
         { to: '/maps', icon: 'mdi-map-marker', text: 'Maps' },
         { to: '/notifications', icon: 'mdi-bell', text: 'Notifications' }
      ],
      show_profile: null,
      tree: [],
      active: [],
      open: [],
      items: [
         { name: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
         { name: 'Order', icon: 'mdi-clipboard-outline', to: '/order' },
         {
            name: 'Setting',
            children: [
               { name: 'User', icon: 'mdi-account-multiple', to: '/users' },
               { name: 'Client', icon: 'mdi-face', to: '/client' },
               { name: 'Area', icon: 'mdi-map-check', to: '/settings/area' },
               { name: 'Zona', icon: 'mdi-map-marker-distance', to: '/settings/zona' },
               { name: 'SLA', icon: 'mdi-table-clock', to: '/settings/sla' },
               { name: 'Roles', icon: 'mdi-account-group', to: '/settings/roles' },
               { name: 'Branch', icon: 'mdi-office-building', to: '/settings/branch' },
               { name: 'Client Mail', icon: 'mdi-email-edit', to: '/settings/client_mail' },
            ],
         },
      ],
   }),
   computed: {
      user() {
         return this.$store.state.auth.user;
      },
      avatar() {
         return CFG.storage_url + '/avatar/' + this.user.id
      },
      drawer: {
         get () {
            return this.$store.state.drawer
         },
         set (val) {
            this.$store.commit('setDrawer', val)
         }
      },
   },
   watch: {
      tree(val) {
         console.log('ch tree', val)
      },
      active(val) {
         console.log('ch active', val)
      },
      open(val) {
         console.log('ch open', val)
      },
   },
   methods: {
      logout() {
         this.$store.dispatch('auth/logout');
         this.$router.push('/login');
      },
      lock() {
         this.$store.dispatch('auth/lock');
      }
   }
}
</script>

<style lang="scss">
#app-drawer {
   .v-treeview {
      font-size: 0.8125rem;
      font-weight: 500;
      line-height: 1rem;
      a {text-decoration: none; color: aquamarine;}
   }
   .v-list__tile {
      border-radius: 4px;

      &--buy {
         margin-top: auto;
         margin-bottom: 17px;
      }
   }
}
</style>
