<template>
   <v-app>
      <router-view />
      <alert />
   </v-app>
</template>

<script>
import cookies from 'js-cookie'
import axios from 'axios'
import CFG from '@/config'
import Alert from './Alert.vue';
import {detail} from '../api/user';
let token;
export default {
   components: { Alert },
   beforeRouteEnter(to, from, next) {
      token = cookies.get('x-auth');
      if(!token) token = cookies.get('x0-auth');
      if(token) {
         axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
         next();
      } else {
         console.log('no tokem', token);
      }
   },
   beforeCreate() {
      if(!this.$store.state.auth.user.id) {
         detail('profile').then(rsl => {
            this.$store.commit('auth/set', rsl.data);
         })
      }
   },
};
</script>
