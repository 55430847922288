<template>
   <v-app>
      <!-- <core-filter /> -->
      <!-- <toolbar v-on:lock="lock" v-on:logout="logout"/> -->
      <drawer v-on:lock="lock" v-on:logout="logout" />
      <v-main>
         <v-fade-transition mode="out-in">
            <router-view />
         </v-fade-transition>
      </v-main>
      <alert />
      <v-overlay z-index="999" style="position:fixed" opacity="0.9" :value="dim_screen" >
         <v-form ref="form" v-if="show_form" @submit.prevent="login">
            <v-card min-width="400">
               <v-card-title>Unlock Session</v-card-title>
               <v-card-text class="px-6">
                  <v-text-field append-icon="mdi-account" label="Login" type="text" v-model="fm.username" :rules="usrRules" readonly />
                  <v-text-field append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
               </v-card-text>
               <v-card-actions>
                  <v-btn text color="lime" @click="logout" >logout</v-btn>
                  <v-spacer />
                  <v-btn outlined rounded width="100px" color="primary" type="submit" :loading="loading">login</v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-overlay>
   </v-app>
</template>

<script>
// import coreFilter from './core/Filter';
// import Toolbar from './Toolbar';
import Drawer from './Drawer';
import cookies from 'js-cookie'
import CFG from '@/config'
import {detail,login} from '../api/user';
import Alert from './Alert.vue';
// import { mapState } from 'vuex'

// let token;
export default {
   name: 'Main',
   components: { Drawer, Alert },
   data: () => ({
      soc: null,
      age: 0,
      check_interval: null,
      show_form: false,
      dim_screen: false,
      fm: {},
      usrRules: [v => !!v || 'Username harus diisi' ],
      pwdRules: [v => !!v || 'Password harus diisi' ],
      loading: false,
      message: 'please login to continue'
   }),
   beforeRouteEnter(to, from, next) {
      const token = cookies.get(CFG.tokenKey);
      if(!token) next('/login');
      else next();
   //    if (to.query.redirectFrom) {
   //       next(vm => {
   //       vm.errorMsg =
   //          "Sorry, you don't have the right access to reach the route requested"
   //       })
   //    } else {
   //       next()
   //    }
   },
   beforeCreate() {
      const {user} = this.$store.state.auth;
      // if(!loggingIn) return this.$router.push('/login');
      if(!user.id) {
         detail('profile').then(rsl => {
            this.$store.commit('auth/set', rsl.data);
         })
      }
   },
   created() {
      this.fm.sid = this.$store.state.auth.sid;
      console.log('create layout');
      this.$soc.listen('dup', () => {
         console.log('Duplicate session detected');
         this.$swal('Ooops!','Akun anda dipergunakan diperangkat lain', 'info');
         this.lock();
      });
      this.sessionStart();
   },
   methods: {
      sessionStart() {
         this.age = 0;
         this.dim_screen = false;
         this.show_form = false;
         clearInterval(this.check_interval);
         this.check_interval = setInterval(this.sessionCheck, 60000);
         window.onmousemove = () => { this.age = 0 };
         window.onkeyup = () => { this.age = 0 };
         // if(window.ws && ws.readyState==1)ws.send(JSON.stringify({typ:'idle',val:0}));
         this.$soc.connect();
      },
      sessionCheck() {
         if(this.age === CFG.session_dim) this.dim();
         if(this.age === CFG.session_age) this.lock();
         this.age++;
         console.log('sess age:', this.age);
      },
      dim() {
         this.dim_screen = true;
         window.onmousemove = this.sessionStart;
         window.onkeyup = this.sessionStart;
         // if(window.ws && ws.readyState==1)ws.send(JSON.stringify({typ:'idle',val:1}));
      },
      lock() {
         this.dim_screen = true;
         clearInterval(this.check_interval);
         window.onmousemove = this.getFm;
         window.onkeyup = this.getFm;
         this.fm.username = this.$store.state.auth.user.user;
         this.$store.dispatch('auth/logout')
         // if(window.ws && ws.readyState==1)ws.send(JSON.stringify({typ:'idle',val:2}));
         this.$soc.close();
      },
      getFm() {
         this.show_form = true;
         window.onmousemove = () => { this.age = 0 };
         window.onkeyup = () => { this.age = 0 };
         this.check_interval = setInterval(this.relock, 30000);
      },
      relock() {
         if(this.age > 0) {
            clearInterval(this.check_interval);
            this.show_form = false;
            window.onmousemove = this.getFm;
            window.onkeyup = this.getFm;
         } else {
            this.age++;
         }
      },
      logout() {
         this.$soc.close();
         this.$store.dispatch('auth/logout');
         this.$router.push('/login');
      },
      del() {
         window.onmousemove=null;
         window.onkeyup=null;
         clearInterval(this.cek_intv);
      },
      // connect() {
      //    if(this.soc) clearTimeout(this.timer_soc);
      //    if(!this.soc || this.soc.readyState === 3) this.soc = new WebSocket(CFG.api_url.replace('http','ws'));
      //    else if(this.soc.readyState === 0) console.log('Connecting to session server ...');
      //    else return;
      //    this.soc.onopen = () => {
      //       const token = cookies.get(CFG.tokenKey);
      //       if(token) {
      //          this.soc.send( JSON.stringify({typ:'login', sid: this.sid, token: token}) );
      //       }
      //    }
      //    this.soc.onclose = ev => {
      //       if(ev.wasClean) return;
      //       console.log('closing:', ev);
      //       this.ttc = this.ttc+1000||1000;
      //       if(this.ttc > 1800000) this.ttc = 1800000;
      //       this.timer_soc = setTimeout(this.connect, this.ttc);
      //    }
      //    this.soc.onmessage = message => {
      //       try {
      //          var json = JSON.parse(message.data);
      //       } catch (e) {
      //          console.log('This doesn\'t look like a valid JSON: ', message.data);
      //          return;
      //       }
      //       switch(json.typ) {
      //          case 'acc':
      //             this.sid = json.sid;
      //             break;
      //          case 'dup':
      //             console.log('Duplicate session detected');
      //             this.$swal('Ooops!','Akun sedang digunakan diperangkat lain', 'info');
      //             this.lock();
      //             // if(!confirm('Akun sedang digunakan diperangkat lain, gunakan diperangkat ini?')) this.lock();
      //             // else {
      //             // 	this.soc.send(JSON.stringify({typ:'use_here'}));
      //             // }
      //          break;
      //          default: console.log('Hmm..., not in mycode : ',JSON.stringify(json));
      //       }
      //    }
      //    this.soc.onerror = function(evt) { console.log('something goes wrong :', evt) };
      // },
      login() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         login(this.fm).then(({stat,mess, ...rsl}) => {
            if(stat === 1) {
               this.$store.dispatch('auth/ok',rsl);
               this.sessionStart();
            } else {
               this.$swal('Ooops!', mess,'info');
               if(stat === 4) {
                  this.change_pwd = true;
                  this.$refs.form.reset();
                  this.token = rsl.token;
               }
            }
            this.fm.password = '';
         }, error => {
            this.$store.dispatch('alert', {title: 'Ooops!', text: (error.response && error.response.data) || error.message || error.toString(), type: 'error'});
         }).finally(() => { this.loading = false });
      }
   },
   destroyed() {
      this.$soc.close();
   }
};
</script>
<style>
.top-nav {background-color: #e8fff4;}
.v-data-table {margin-top: 20px;}
.v-card__actions > .v-btn.v-btn { width: 108px; }
.label-color { color: #4b8; }
.client-box {max-height: 300px; overflow: auto;}
</style>